<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none" v-if="showToggleVerticalMenu">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <b-link class="navbar-brand" to="/">
        <span class="brand-logo">
          <b-img :src="appLogoImage" :alt="appName" height="35" />
        </span>
      </b-link>
      <!-- Bookmarks Container -->
      <!-- <bookmarks /> -->
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-dropdown
        v-if="showDropdownSite"
        :text="currentSiteName"
        variant="outline-primmary"
        right
        class="border current-site-name"
      >
        <vue-perfect-scrollbar class="fr-list-site">
          <b-dropdown-group>
            <template v-for="(property, idx) in siteDisplayByUser">
              <b-dropdown-item
                :key="idx"
                @click="site_onChange(property.SiteID)"
                :class="currentSiteID == property.SiteID ? 'active' : ''"
              >
                <b-media :title="property.SiteName || ''">
                  <template #aside>
                    <b-avatar size="40px" variant="secondary">
                      <ez-icon icon="ez_apartment" size="22" />
                    </b-avatar>
                  </template>

                  <h5 class="text-truncate" style="width: 175px">
                    {{ property.SiteName }}
                  </h5>
                  <b-card-text class="mb-0 text-truncate" style="width: 175px">
                    {{ property.TaxCode }}
                  </b-card-text>
                </b-media>
              </b-dropdown-item>
            </template>
          </b-dropdown-group>
        </vue-perfect-scrollbar>
        <b-dropdown-divider v-if="siteDisplayByUser.length > 0" />
        <b-dropdown-item class="text-center">
          <span class="text-primary" @click="viewAllSite_onClick">{{
            $t('common_view_all_site')
          }}</span>
        </b-dropdown-item>
      </b-dropdown>

      <!-- <b-link class="nav-link">
        <ez-icon
          icon="ez_grid_view"
          color="primary"
          size="23"
        />
      </b-link> -->

      <!-- <search-bar /> -->
      <locale />
      <!-- <dark-Toggler class="d-none d-lg-block" /> -->

      <!-- <cart-dropdown /> -->
      <!-- <notification-dropdown /> -->
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav, BImg } from 'bootstrap-vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import vSelect from 'vue-select';
// import Bookmarks from './components/Bookmarks.vue';
import Locale from './components/Locale.vue';
import SearchBar from './components/SearchBar.vue';
// import DarkToggler from './components/DarkToggler.vue';
// import CartDropdown from './components/CartDropdown.vue';
// import NotificationDropdown from './components/NotificationDropdown.vue';
import UserDropdown from './components/UserDropdown.vue';
import { $themeConfig } from '@themeConfig';
export default {
  components: {
    vSelect,
    BLink,
    BImg,
    // Navbar Components
    BNavbarNav,
    // Bookmarks,
    Locale,
    SearchBar,
    // DarkToggler,
    // CartDropdown,
    // NotificationDropdown,
    UserDropdown,
    VuePerfectScrollbar,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app;
    return {
      // App Name
      appName,
      appLogoImage,
    };
  },
  methods: {
    site_onChange(siteID) {
      if (siteID !== this.currentSiteID) {
        this.$store.dispatch('auth/switchSite', siteID);
      }
    },
    viewAllSite_onClick() {
      this.$router.push({ name: 'site-management' });
    },
  },
  computed: {
    listProperty() {
      return this.$store.getters['app/getSites'];
    },
    siteDisplayByUser() {
      return this.listProperty.filter(s => 
          !['SystemSupport', 'SystemAdmin'].includes(s.GroupRoleCode) 
          || (this.currentSiteID && s.SiteID === this.currentSiteID));
    },
    currentSiteID() {
      return this.$store.getters['auth/currentSiteID'];
    },
    currentSiteName() {
      const currentPropertyDetail = this.listProperty.find(
        (s) => s.SiteID == this.currentSiteID
      );
      if (typeof currentPropertyDetail !== 'undefined') {
        return currentPropertyDetail.SiteName;
      }
      return '';
    },
    showDropdownSite() {
      return this.listProperty.length > 0;
    },
    showToggleVerticalMenu() {
      return this.currentSiteID && this.showDropdownSite;
    },
  },
};
</script>

<style lang="scss">
.navbar-container {
  .nav {
    .current-site-name {
      max-width: 500px;
      button {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .fr-list-site {
      max-height: calc(100vh - 150px);
      li.active {
        a {
          background-color: rgba(17, 74, 159, 0.12);
        }
      }
    }
  }
}
</style>
